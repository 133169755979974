var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"headline font-weight-bold text-center mb-10"},[_vm._v("Daily Coworking")]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":['summer'],"label":"Select internship cycle"},model:{value:(_vm.internshipCycle),callback:function ($$v) {_vm.internshipCycle=$$v},expression:"internshipCycle"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ATextInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Coworking Name"},model:{value:(_vm.coworkingName),callback:function ($$v) {_vm.coworkingName=$$v},expression:"coworkingName"}})]}}])}),_c('div',{staticClass:"text-start mb-2"},[_c('h4',[_vm._v("Select recurring days per week")])]),_c('v-row',{staticClass:"mt-5 mb-5"},_vm._l((_vm.weekDaysList),function(item){return _c('div',{key:item,staticClass:"d-flex justify-content: center"},[_c('v-col',[_c('a-button',{staticClass:"white--text",attrs:{"id":"day","width":"10px","rounded":"","color":_vm.daysPerWeekCoworking.includes(item) ? 'blue' : 'grey'},on:{"click":function($event){_vm.daysPerWeekCoworking.includes(item)
              ? _vm.daysPerWeekCoworking.splice(_vm.daysPerWeekCoworking.indexOf(item), 1)
              : _vm.daysPerWeekCoworking.push(item)}}},[_vm._v(_vm._s(item.split('')[0]))])],1)],1)}),0),_c('div',{staticClass:"headline font-weight-bold ml-2 mt-2 mb-2"},[_vm._v("Select recurring hours per day")]),_c('v-row',{staticClass:"mt-5 mb-5"},_vm._l((_vm.hoursList),function(item){return _c('div',{key:item,staticClass:"d-flex justify-content: center"},[_c('v-col',[_c('a-button',{staticClass:"white--text",attrs:{"id":"hours","width":"10px","rounded":"","color":_vm.hoursItem.includes(item) ? 'blue' : 'grey'},on:{"click":function($event){_vm.hoursItem.includes(item)
              ? _vm.hoursItem.splice(_vm.hoursItem.indexOf(item), 1)
              : _vm.hoursItem.push(item)}}},[_vm._v(_vm._s(item))])],1)],1)}),0),_c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-1",attrs:{"cols":"12"}},[_c('v-menu',{ref:"coworkingDateRef",attrs:{"close-on-content-click":false,"return-value":_vm.coworkingDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.coworkingDate=$event},"update:return-value":function($event){_vm.coworkingDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rounded":"","outlined":"","label":"Start Date","readonly":"","error-messages":errors},model:{value:(_vm.coworkingDate),callback:function ($$v) {_vm.coworkingDate=$$v},expression:"coworkingDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.coworkingDateRef),callback:function ($$v) {_vm.coworkingDateRef=$$v},expression:"coworkingDateRef"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.coworkingDate),callback:function ($$v) {_vm.coworkingDate=$$v},expression:"coworkingDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.coworkingDateRef = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.coworkingDateRef.save(_vm.coworkingDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.coworkingRepeatTimes,"label":"Repeat every"},model:{value:(_vm.coworkingRepeat),callback:function ($$v) {_vm.coworkingRepeat=$$v},expression:"coworkingRepeat"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.coworkingOccurenceList,"label":"End after"},model:{value:(_vm.coworkingOccurence),callback:function ($$v) {_vm.coworkingOccurence=$$v},expression:"coworkingOccurence"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-3",attrs:{"error-messages":errors,"rounded":"","outlined":"","hint":"workLocation","label":"Work Location"},model:{value:(_vm.workLocation),callback:function ($$v) {_vm.workLocation=$$v},expression:"workLocation"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ATextInput',{staticClass:"mb-3",attrs:{"error-messages":errors,"rounded":"","outlined":"","hint":"Description","label":"Description"},model:{value:(_vm.coworkingDescription),callback:function ($$v) {_vm.coworkingDescription=$$v},expression:"coworkingDescription"}})]}}])}),_c('div',{staticClass:"headline font-weight-bold"},[_vm._v("Who would you like to add to this calendar?")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-checkbox',{attrs:{"label":"Employer","value":"employer"},model:{value:(_vm.coworkingSelected),callback:function ($$v) {_vm.coworkingSelected=$$v},expression:"coworkingSelected"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Current Interns","value":"intern"},model:{value:(_vm.coworkingSelected),callback:function ($$v) {_vm.coworkingSelected=$$v},expression:"coworkingSelected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }