<template>
  <v-container>
    <div class="headline font-weight-bold text-center mb-10">Daily Coworking</div>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="internshipCycle"
        :error-messages="errors"
        rounded
        outlined
        :items="['summer']"
        label="Select internship cycle"
      />
    </validation-provider>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ATextInput
        v-model="coworkingName"
        :error-messages="errors"
        rounded
        outlined
        label="Coworking Name"
      />
    </validation-provider>

    <div class="text-start mb-2"><h4>Select recurring days per week</h4></div>
    <v-row class="mt-5 mb-5">
      <div v-for="item in weekDaysList" :key="item" class="d-flex justify-content: center">
        <v-col>
          <a-button
            id="day"
            class="white--text"
            width="10px"
            rounded
            :color="daysPerWeekCoworking.includes(item) ? 'blue' : 'grey'"
            @click="
              daysPerWeekCoworking.includes(item)
                ? daysPerWeekCoworking.splice(daysPerWeekCoworking.indexOf(item), 1)
                : daysPerWeekCoworking.push(item)
            "
            >{{ item.split('')[0] }}</a-button
          >
        </v-col>
      </div>
    </v-row>

    <div class="headline font-weight-bold ml-2 mt-2 mb-2">Select recurring hours per day</div>
    <v-row class="mt-5 mb-5">
      <div v-for="item in hoursList" :key="item" class="d-flex justify-content: center">
        <v-col>
          <a-button
            id="hours"
            class="white--text"
            width="10px"
            rounded
            :color="hoursItem.includes(item) ? 'blue' : 'grey'"
            @click="
              hoursItem.includes(item)
                ? hoursItem.splice(hoursItem.indexOf(item), 1)
                : hoursItem.push(item)
            "
            >{{ item }}</a-button
          >
        </v-col>
      </div>
    </v-row>

    <v-container class="mt-6" fluid>
      <v-row>
        <v-col cols="12" class="pl-0 pr-1">
          <v-menu
            ref="coworkingDateRef"
            v-model="coworkingDateRef"
            :close-on-content-click="false"
            :return-value.sync="coworkingDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-text-field
                  v-model="coworkingDate"
                  rounded
                  outlined
                  label="Start Date"
                  readonly
                  :error-messages="errors"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker v-model="coworkingDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="coworkingDateRef = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.coworkingDateRef.save(coworkingDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="coworkingRepeat"
        :error-messages="errors"
        rounded
        outlined
        :items="coworkingRepeatTimes"
        label="Repeat every"
      />
    </validation-provider>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ASelectInput
        v-model="coworkingOccurence"
        :error-messages="errors"
        rounded
        outlined
        :items="coworkingOccurenceList"
        label="End after"
      />
    </validation-provider>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <v-textarea
        v-model="workLocation"
        :error-messages="errors"
        rounded
        outlined
        hint="workLocation"
        class="mb-3"
        label="Work Location"
      />
    </validation-provider>

    <validation-provider v-slot="{ errors }" slim rules="required">
      <ATextInput
        v-model="coworkingDescription"
        :error-messages="errors"
        rounded
        outlined
        hint="Description"
        class="mb-3"
        label="Description"
      ></ATextInput>
    </validation-provider>

    <div class="headline font-weight-bold">Who would you like to add to this calendar?</div>
    <v-container fluid>
      <v-checkbox v-model="coworkingSelected" label="Employer" value="employer"></v-checkbox>
      <v-checkbox
        v-model="coworkingSelected"
        label="Current Interns"
        value="intern"
        class="mt-0"
      ></v-checkbox>
    </v-container>
  </v-container>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import { useUserState } from '@/store';
import ATextInput from '@/components/atoms/ATextInput.vue';
import ASelectInput from '@/components/atoms/ASelectInput.vue';
import AButton from '@/components/atoms/AButton.vue';
import { ValidationProvider } from '@/validation';

export default {
  components: {
    ATextInput,
    ASelectInput,
    AButton,
    ValidationProvider
  },
  props: {
    isGoogleConnection: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props, ctx) {
    const { user } = useUserState(['user']);
    let weekDaysList;
    if (props?.isGoogleConnection) {
      weekDaysList = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    } else {
      weekDaysList = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    }
    const hoursList = [
      '8am',
      '9am',
      '10am',
      '11am',
      '12pm',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm'
    ];
    const coworkingTimeZoneList = ['(GMT-7:00)Pacific Time - Los Angeles', 'Asia/Kolkata'];
    const coworkingRepeatTimes = ['1 Week', '2 Week', '3 Week', '4 Week', '5 Week'];
    const coworkingOccurenceList = [
      '1 Occurrences',
      '2 Occurrences',
      '3 Occurrences',
      '4 Occurrences',
      '5 Occurrences',
      '6 Occurrences'
    ];
    const coworkingDateRef = false;
    const internshipCycle = ref('');
    const coworkingName = ref('');
    const coworkingTimeZone = ref('');
    const daysPerWeekCoworking = ref([]);
    const hoursItem = ref([]);
    const coworkingDate = ref('');
    const coworkingRepeat = ref('');
    const coworkingOccurence = ref('');
    const coworkingConferenceLine = ref('');
    const workLocation = ref('');
    const coworkingDescription = ref('');
    const coworkingSelected = ref([]);
    watch(
      [
        coworkingName,
        coworkingTimeZone,
        daysPerWeekCoworking,
        hoursItem,
        coworkingDate,
        coworkingRepeat,
        coworkingOccurence,
        coworkingConferenceLine,
        workLocation,
        coworkingDescription,
        coworkingSelected
      ],
      ([
        coworkingName,
        coworkingTimeZone,
        daysPerWeekCoworking,
        hoursItem,
        coworkingDate,
        coworkingRepeat,
        coworkingOccurence,
        coworkingConferenceLine,
        workLocation,
        coworkingDescription,
        coworkingSelected
      ]) => {
        const coworkingObject = {
          coworkingName: coworkingName || undefined,
          coworkingTimeZone: coworkingTimeZone || undefined,
          daysPerWeekCoworking: daysPerWeekCoworking || undefined,
          hoursItem: hoursItem || undefined,
          coworkingDate: coworkingDate || undefined,
          coworkingRepeat: coworkingRepeat || undefined,
          coworkingOccurence: coworkingOccurence || undefined,
          coworkingConferenceLine: coworkingConferenceLine || undefined,
          workLocation: workLocation || undefined,
          coworkingDescription: coworkingDescription || undefined,
          coworkingSelected: coworkingSelected || undefined
        };
        ctx.emit('coworkingEmit', coworkingObject);
      }
    );

    return {
      weekDaysList,
      coworkingTimeZoneList,
      hoursList,
      coworkingDateRef,
      coworkingRepeatTimes,
      coworkingOccurenceList,
      internshipCycle,
      coworkingName,
      coworkingTimeZone,
      daysPerWeekCoworking,
      hoursItem,
      coworkingDate,
      coworkingRepeat,
      coworkingOccurence,
      coworkingConferenceLine,
      workLocation,
      coworkingDescription,
      coworkingSelected
    };
  }
};
</script>

<style>
#day {
  min-width: 36px;
}
#hours {
  min-width: 43px;
}
</style>
